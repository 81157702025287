

.seleccionInvitacion:hover{
    background-color:rgba(0, 0, 0, 0.05) !important;
}

.seleccionInvitacion:hover .colorTextoDopoInvita{
color:rgb(17, 24, 39)!important;

}

.seleccionInvitacion2{
    background-color:rgba(0, 0, 0, 0.05) !important;
}

.seleccionInvitacion2 .colorTextoDopoInvita{
color:rgb(17, 24, 39)!important;

}

/* .seleccionInvitacion:hover .texteoInvitaDe{
    font-weight: 500!important;
} */

.classesListaInvita{
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    width: 100%;
    align-items: flex-start;
}
@media screen and (min-width: 960px) {
    .classesListaInvita{
        padding-left: 8px!important;
        padding-right: 8px!important;
    }
    
}

.sinhovericbut:hover{
    cursor:default!important;
    
}

.hovereoListoca{
    border-radius: 4px !important;
}

.hovereoListoca:hover{
    background-color: rgba(0, 0, 0, 0.05)!important;
    cursor:pointer !important;
}

.hovereoListoca2{
    border-radius: 0px !important;
}

.hovereoListoca2:hover{
    background-color: rgba(0, 0, 0, 0.05)!important;
    cursor:pointer !important;
}


/* .mensajeReclut{
    background-color: #2b6068;
}
 */
.mensajeReclut:hover{
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer !important;
    /* color:#fff; */
}



.mensajeReclutPadre:hover > .mensajeReclut {
    background-color:transparent!important;
    cursor: pointer !important;
}

.mensajeReclutPadre:hover  {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer !important;
} 

.mensajeReclut:hover  {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer !important;
}

/* .mensajeReclutPadre:hover  {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer !important;
} */

@media (min-width: 643px) and (max-width: 814px){
    .mediaBen4{
        width: 43% !important;
    }
    }



    .hovereoBotonMensajes:hover{
        background-color: rgba(0, 0, 0, 0.04)!important;
        
    }
    

    .dialogoElim{
        max-width: 500px!important;
        margin: auto !important;
    }