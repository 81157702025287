.hovereoBotonMensajes:hover{
    background-color: rgba(0, 0, 0, 0.04)!important;
    
}

.noMensajesMail{
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.adicionalScroller{
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;
}

#olo:hover #download {
opacity:0.3!important
}

#leop44:hover + #olo {
  padding-left: 4px!important;
  padding-right: 4px!important;
  padding-top: 4px!important;
  padding-bottom: 4px!important;
    }

.listadoSecundarioMen{
    right: 0px !important;
}

.listaSecondaryTag{
    position: relative!important;
    transform: none!important;
    right: unset !important;
}

.imequeMensa{
    height:calc(100px - 16px)!important;
    width: auto !important;
}

.imequeMensa:hover{
    height:calc(100px - 8px)!important;
    width: auto !important;
}

@media screen and  (min-width: 600px) {

    .margeneoBotonesBuscadorDer{
        margin-top: 0!important;
        margin-left: 8px!important;
        margin-right: 8px!important;
    }
}

.hovereoListoca2{
    border-radius: 0px !important;
}

.hovereoListoca2:hover{
    background-color: rgba(0, 0, 0, 0.05)!important;
    cursor:pointer !important;
}